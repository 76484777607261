<template>
  <div class="test">
    <div class="top" @click="backTop">
      <i class="el-icon-caret-top"></i>
    </div>
  </div>
</template>

<script>
window.onscroll = () => {
  let top = document.documentElement.scrollTop;
  if (top >= 900) {
    document.querySelector(".top").style.display = "block";
  } else {
    document.querySelector(".top").style.display = "none";
  }
};

export default {
  data() {
    return {
      list: [1, 2, 3]
    };
  },
  methods: {
    backTop() {
      let timeId = setInterval(() => {
        console.log("timeId", timeId);
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -=
            5000 / document.documentElement.scrollTop;
        } else {
          clearInterval(timeId);
        }
      }, 10);
    }
  }
};
</script>

<style lang="less" scoped>
.test {
  height: 2400px;
  background-color: #ddd;
  .top {
    position: fixed;
    right: 100px;
    bottom: 150px;
    color: #409eff;
    background-color: #fff;
    box-shadow: 0 0 6px rgb(0 0 0 / 12%);
    border-radius: 50%;
    font-size: 30px;
    cursor: pointer;
    display: none;
  }
}
</style>